import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Meet the Team</h2>
            <p>Introducing the Exceptional Team Behind Our Success</p>
            <br />
            <br />
          </div>
        </div>
        <div className="row justify-content-center">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className=" col-md-4 col-sm-6 team">
                  <div className="thumbnail">
                    {/* <img src={d.img} alt="..." className="team-img" /> */}
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
